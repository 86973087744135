import React, { Component } from "react";
import { Link } from "gatsby";
import ReactHtmlParser from 'react-html-parser';
import Layout from "../components/layout";
import "../css/style.css";
import PopupForm from "./popupForm";
import { navigate } from "gatsby"

const _ = require(`lodash`);
class InstituteTabs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			time: 90000000,
		};
	}

	handlePopupWindow = () => {
		this.setState({ time: Math.random() });
	};

	// backClick = e => {
	// 	e.preventDefault()
	// 	history.back()
	//   }

	render() {
		//     console.log("Hello"+this.props.BannerRightDetail);
		//     const BannerRightDetailData = this.props.BannerRightDetail;
		//     console.log("Hello0000"+BannerRightDetailData.length);
		//console.log("helllllllllll");
		//console.log("Hello"+JSON.stringify(this.props));
		return (
			<div>
				<PopupForm time={this.state.time} />
				<div className="container">
					<ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
						<li class="breadcrumb-item">
							<a href="/program-listing/">Degree Finder </a>
						</li>
						<li class="breadcrumb-item">
							<a href={`/program-listing/?Country=${this.props.CountryType}`}>{this.props.CountryType}</a>
						</li>
						{
                    		this.props.DegreeType === 'Undergraduate' ? (
								<li class="breadcrumb-item">
									<a href={`/program-listing/?Country=${this.props.CountryType}&DegreeType=Undergraduate`}>UG</a>
								</li>):(
								<li class="breadcrumb-item">
									<a href={`/program-listing/?Country=${this.props.CountryType}&DegreeType=Postgraduate`}>PG</a>
								</li>
							)
						}
						<li class="breadcrumb-item active"><a>{this.props.ProgramName}</a></li>
					</ol>
				</div>
				<div 
					class="program-detail-banner-sec bg-cover480br d-flex justify-content-center align-items-center position-relative" 
					style={{ 
						fontFamily: "'Poppins', sans-serif;", 
						backgroundImage: `url(${this.props.UniversityImage})` 
					}}>
						<div class="opacity-extra-medium bg-medium-slate-blue"></div>
					<div class="container">
						<div class="row">
							<div class="col-12">
								<div class="program-detail-banner-inner">
									<div class="row" style={{fontFamily: "'Poppins', sans-serif;"}}>
										<div class="col-md-8 md-margin-50px-bottom">
												<div class="text-box margin-30px-bottom">
													<h1 class="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30  margin-10px-bottom">{this.props.ProgramName}</h1>
													{this.props.ProgramDetail.length > 10 ? (
														<p class="alt-font text-white" >{ReactHtmlParser(this.props.ProgramDetail)}</p>
													) : (
														<p class="alt-font text-white">Get Your {this.props.DegreeType} degree in {this.props.DegreeArea} through The WorldGrad</p>
													)}
													<a id="book-councelling-btn" class="btn btn-theme effect btn-md  text-center" onClick={() => this.handlePopupWindow()}>
														Book a counselling session
													</a>
												</div>
												<div class="text-inner">
													{this.props.ProgramSponserdImage2 ? (
														<div>
															<p class="alt-font text-white text-small margin-10px-bottom">Program offered by</p>
															{this.props.UniversityLogo !== null ? (
															<div class="pro-detail-banner-logo d-inline-block">
																<img src={this.props.ProgramSponserdImage2.source_url} alt="Program Sponser" class="w-100" />
															</div>
															) : (
																<div></div>
															)}
															<div></div>
														</div>
													) : (
														""
													)}
												</div>
										</div>
										{this?.props?.BannerRightDetail && (
											<div class="col-md-4">
												<div class="detail-banner-right">
													{this.props.BannerRightDetail.slice(0, 2).map((item, index) => {
														return (
															<div>
																{item?.banner_right_text && (
																	<div class="detail-banner-right-box">
																		<div class="image-holder">
																			<img src={item.banner_right_icon.source_url} alt="Icon" />
																		</div>
																		<div class="text-box last-paragraph-no-margin">
																			<h3>{item.banner_right_text}</h3>
																			<p class="refund-policy">{item.banner_right_subtext}</p>
																		</div>
																	</div>
																)}
															</div>
														);
													})}
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
					<div class="monthly-duration-sec" style={{ backgroundColor: "#f8f8fa" }}>
						<div class="container">
							<div class="row">
								<div class="col-12">
									<ul>
										{this.props.ProgramDurationTitle ? (
										<li class="mrtps-trpe">
											<p>{this.props.ProgramDurationTitle}</p>
											<h4 class="font-weight-400">{this.props.ProgramDuration}</h4>
											<hr className="program-detail-horizontal-line" />
										</li>
										) : ("")}
										{this.props.Term1Title ? (
										<li>
											<p>{this.props.Term1Title}</p>
											<a href={this.props.programLink}>
												<h4 class="font-weight-400" style={{ color: "#F0A500" }}>{this.props.Term1Info}</h4>
											</a>
											<h4>{this.props.Term1option2}</h4>
											<hr className="program-detail-horizontal-line" />
										</li>
										) : ("")}
										{this.props.Term2Title ? (
										<li class="mrtps-trpe">
											<p>{this.props.Term2Title}</p>
											<h4 class="font-weight-400">{this.props.Term2Info}</h4>
									 		<hr className="program-detail-horizontal-line" />
										</li>
										) : ("")}
										{this.props.Term3Title ? (
										<li>
										<p>{this.props.Term3Title}</p>
										{this.props.UniversityLink ? (
											<a href={this.props.UniversityLink} className="font-weight-400" style={{ color: "#F0A500" }}>{this.props.Year3Info}</a>
										) : (
											<h4 className="font-weight-400">{this.props.Year3Info}</h4>
										)}
									</li>
									
										) : ("")}
									</ul>

									
								</div>
							</div>
						</div>
					</div>
				
			</div>
		);
	}
}

export default InstituteTabs;
